import { easePolyOut } from "d3-ease"
import { useIntl } from "gatsby-plugin-intl"
import React, { useMemo } from "react"
import {
  animated,
  useChain,
  useSpring,
  useSpringRef,
  useTransition,
} from "react-spring"
import "./useModal.scss"
// make a hook that returns the model control
export const useModal = (): [
  (children: any) => any,
  boolean,
  () => void,
  () => void
] => {
  const intl = useIntl()
  const [isOpen, setIsOpen] = React.useState(false)

  const openModal = () => {
    setIsOpen(true)
  }

  const closeModal = () => {
    setIsOpen(false)
  }

  const props = useSpring({
    opacity: isOpen ? 1 : 0,
    config: {
      duration: 50,
    },
  })
  const transitionRef = useSpringRef()
  const contentTransition = useTransition(isOpen, {
    from: { transform: "translateY(1000px)" },
    enter: { transform: "translateY(0)" },
    leave: { transform: "translateY(1000px)" },
    config: {
      duration: 300,
      easing: easePolyOut,
    },
  })

  const modalRender = useMemo(() => {
    return ({ children }: any) => {
      if (!isOpen) {
        return null
      }
      return (
        <animated.div style={props} className="use-modal">
          {contentTransition((styles, item) => {
            return (
              item && (
                <animated.div
                  style={styles}
                  className="use-modal-content w-full fixed insert-x-0 bottom-0 bg-white border-t border-black"
                >
                  <div>{children}</div>
                  <div
                    onClick={() => {
                      closeModal()
                    }}
                    className="rounded-full border border-black w-full h-8 ABC-Mono flex items-center justify-center"
                  >
                    {intl.formatMessage({ id: "close" })}
                  </div>
                </animated.div>
              )
            )
          })}
        </animated.div>
      )
    }
  }, [isOpen])

  return [modalRender, isOpen, openModal, closeModal]
}
