import * as React from "react"
import { PlayerContext } from "../components/audioPlayerWrapper"
import { graphql, Link } from "gatsby"
import * as R from "ramda"
import { useContext } from "react"
import { IArtist, IRelease, ISong } from "../types/models"
import "../styles/releaseDetail.scss"
import { useIntl } from "gatsby-plugin-intl"
import moment from "moment"
import NotFoundPage from "../pages/404"
import { useModal } from "../components/useModal"
import { useEffect, useRef } from "react"
import { useMobile } from "../components/useBreakPoint"
import { Popover } from "react-tiny-popover"
import {
  TwitterShareButton,
  WeiboShareButton,
  FacebookShareButton,
} from "react-share"
import Seo from "../components/seo"
function toHHMM(secondsNumber: number) {
  let sec_num = Math.ceil(secondsNumber) // don't forget the second param
  let hours = Math.floor(sec_num / 3600)
  let minutes: string | number = Math.floor((sec_num - hours * 3600) / 60)
  let seconds: string | number = sec_num - hours * 3600 - minutes * 60

  if (minutes < 10) {
    minutes = minutes
  }
  if (seconds < 10) {
    seconds = "0" + seconds
  }
  return minutes + ":" + seconds
}

export default ({ data, pageContext }: any) => {
  const intl = useIntl()
  const consumer = useContext(PlayerContext)
  const [Modal, isOpen, openModal, closeModal] = useModal()
  const [currentModelSong, setCurrentModelSong] = React.useState<ISong>()
  const [isCreditsPopoverOpen, setIsCreditsPopoverOpen] = React.useState(false)
  const [openShare, toggleShare] = React.useState<boolean>(false)
  const descRef = useRef(null)
  const [isMobile] = useMobile()

  let currentWithoutLocaleFilter: IRelease[] = React.useMemo<IRelease[]>(
    () =>
      R.pipe(
        R.pathOr([], ["allStrapiRelease", "nodes"]),
        R.filter(R.propEq("slug", pageContext.slug))
      )(data),
    [pageContext.slug]
  )
  let current: IRelease | null = R.find(R.propEq("locale", intl.locale))(
    currentWithoutLocaleFilter
  ) as IRelease
  const otherLocal = R.without<string>([intl.locale])(["en", "zh", "ja"])

  const reRenderDescriptionMargin = () => {
    if (descRef.current) {
      const windowWidth = document.documentElement.clientWidth
      const imageEle = document.querySelector(
        ".cover-wrapper img"
      ) as HTMLElement
      if (windowWidth > 1605) {
        descRef.current.style.marginTop = `${
          imageEle.clientHeight - 82 - 721 + 2
        }px`
      } else {
        descRef.current.style.marginTop = "0"
      }
    }
  }
  useEffect(() => {
    if (isMobile) {
      return
    }
    window.onresize = reRenderDescriptionMargin
    reRenderDescriptionMargin()
  }, [1])

  if (!current) {
    current =
      (R.find(R.propEq("locale", otherLocal[0]))(
        currentWithoutLocaleFilter
      ) as IRelease) ||
      (R.find(R.propEq("locale", otherLocal[1]))(
        currentWithoutLocaleFilter
      ) as IRelease)
  }
  if (!current) {
    return <NotFoundPage />
  }

  const prevSlug = React.useMemo(
    () =>
      R.pipe(
        R.pathOr<IRelease[]>([], ["allStrapiRelease", "nodes"]),
        R.filter(i => R.path(["locale"])(i) === intl.locale),
        R.findIndex(R.propEq<string>("slug", pageContext.slug)),
        R.add(-1),
        //@ts-ignore
        R.nth<IRelease>(
          R.__,
          R.pipe(
            R.pathOr<IRelease[]>([], ["allStrapiRelease", "nodes"]),
            R.filter(i => R.path(["locale"])(i) === intl.locale)
          )(data)
        ),
        R.prop("slug")
        //@ts-ignore
      )(data),
    [current.slug]
  )

  const nextSlug = React.useMemo(
    () =>
      R.pipe(
        R.pathOr<IRelease[]>([], ["allStrapiRelease", "nodes"]),
        R.filter(i => R.path(["locale"])(i) === intl.locale),
        R.findIndex(R.propEq<string>("slug", pageContext.slug)),
        R.add(1),
        //@ts-ignore
        R.nth<IRelease>(
          R.__,
          R.pipe(
            R.pathOr<IRelease[]>([], ["allStrapiRelease", "nodes"]),
            R.filter(i => R.path(["locale"])(i) === intl.locale)
          )(data)
        ),
        R.prop("slug")
        //@ts-ignore
      )(data),
    [current.slug]
  )

  const songs = R.pathOr<ISong[]>([], ["songs"])(current)

  const renderSerialNumber = () => {
    const serialNumber = R.path<string>(["serial_number"])(current)
    if (serialNumber) {
      return (
        <div className="flex items-center gap-2 serial-number ABC">
          {!!prevSlug && (
            <Link to={`/${intl.locale}/releases/${prevSlug}`}>
              <img
                src={require("../images/arrow-up.svg").default}
                className="arrow-up"
              />
            </Link>
          )}
          <p>{serialNumber}</p>
          {!!nextSlug && (
            <Link to={`/${intl.locale}/releases/${nextSlug}`}>
              <img
                src={require("../images/arrow-down.svg").default}
                className="arrow-down"
              />
            </Link>
          )}
        </div>
      )
    }
    return null
  }

  // map genres to string
  const genresString = current.genres.map(genre => <span>{genre.name}</span>)

  const artistNames = R.pipe(
    R.pathOr([], ["artists"]),
    R.map((artist: IArtist) => (
      <Link
        to={`/${intl.locale}/artists/${artist.slug}`}
        className="artist-link"
      >
        {artist.name}
      </Link>
    )),
    R.ifElse(
      R.gt(R.length(R.__, 2)),
      R.pipe(
        R.intersperse(intl.locale === "en" ? <span>, </span> : <span>，</span>),
        R.update(-2, <span> & </span>)
      ),
      R.intersperse(<span> & </span>)
    )
  )(current)

  const renderSong = (song: ISong, idx: number) => {
    const isCurrentSong = (song: ISong) => {
      return (
        consumer.playlist[consumer.currentPlayIndex] &&
        song.id === consumer.playlist[consumer.currentPlayIndex].id
      )
    }

    const currentDuration = R.pipe(
      R.pathOr("", ["track", "alternativeText"]),
      (durationText: string) => {
        if (!!durationText && !!durationText?.match(/\{\{(.*)\}\}/)) {
          //@ts-ignore
          return Math.ceil(Number(durationText?.match(/\{\{(.*)\}\}/)[1]))
        }
      }
    )(song)

    const renderModal = () => {
      const open = isOpen && song?.id === currentModelSong?.id
      return (
        <button
          onClick={() => {
            setCurrentModelSong(song)
            open ? closeModal() : openModal()
          }}
        >
          {open ? (
            <img
              style={{ width: 17, height: 17 }}
              src={require("../images/song-info-active.svg").default}
            />
          ) : (
            <img
              style={{ width: 17, height: 17 }}
              src={require("../images/song-info.svg").default}
            />
          )}
        </button>
      )
    }

    const renderPopover = () => {
      const open = isCreditsPopoverOpen && song?.id === currentModelSong?.id
      return (
        <Popover
          isOpen={open}
          positions={["right"]}
          content={() => (
            <div className="credits-popover bg-white border border-black">
              {renderCurrentModalContent()}
            </div>
          )}
          onClickOutside={() => setIsCreditsPopoverOpen(false)}
          padding={18}
        >
          <button
            onClick={() => {
              setCurrentModelSong(song)
              setIsCreditsPopoverOpen(!isCreditsPopoverOpen)
            }}
          >
            {open ? (
              <img
                style={{ width: 17, height: 17 }}
                src={require("../images/song-info-active.svg").default}
              />
            ) : (
              <img
                style={{ width: 17, height: 17 }}
                src={require("../images/song-info.svg").default}
              />
            )}
          </button>
        </Popover>
      )
    }

    const onClickPlay = () => {
      if (isCurrentSong(song)) {
        consumer.togglePause()
        !!consumer.hidePlayer && consumer.setHidePlayer(false)
      } else {
        //merge artist and song
        const playlist = R.pipe(
          R.map((_song: ISong) => ({
            ..._song,
            //@ts-ignore
            artists: R.pathOr([], ["allStrapiSong", "nodes"], data).find(
              R.propEq("id", `Song_${_song.id}`)
            ).artists,
          }))
        )(songs)
        consumer.setPlayList(playlist, idx)
        consumer.setHidePlayer(false)
      }
    }

    return (
      <li
        key={song.id}
        className="detail-song flex items-center justify-between w-full"
      >
        <button
          onClick={onClickPlay}
          className="flex gap-4 flex-1 items-center md:flex-shrink-0 play-button  "
        >
          <button
            className={`flex-shrink-0 border border-black rounded-full ${
              isCurrentSong(song) ? (consumer.pause ? "pause" : "playing") : ""
            }`}
            style={{ width: 24, height: 24 }}
          />
          <div className="song-title cursor-pointer">{song.name}</div>
        </button>
        <div className="flex items-center justify-end md:flex-1 md:flex-shrink pl-6 ABC">
          <div className="flex gap-4">
            <div className="song-duration ABC-Mono">
              {toHHMM(currentDuration as number)}
            </div>
            {isMobile ? renderModal() : renderPopover()}
          </div>
        </div>
      </li>
    )
  }

  const renderShareContent = () => {
    const shareTitle = `${current?.name} - ${(current?.artists || [])
      .map(i => i.name)
      .join("/")}`
    const url = encodeURI(window.location.href)
    return (
      <div className="release-share-wrapper bg-white px-4 py-5 border border-black flex flex-col gap-2 ABC-Mono">
        <ul>
          <li>
            <TwitterShareButton title={shareTitle} url={url}>
              Twitter
            </TwitterShareButton>
          </li>
          <li>
            <FacebookShareButton title={shareTitle} url={url}>
              Facebook
            </FacebookShareButton>
          </li>
          <li>
            <WeiboShareButton title={shareTitle} url={url}>
              Weibo
            </WeiboShareButton>
          </li>
        </ul>
      </div>
    )
  }

  const renderCurrentModalContent = () => {
    if (!currentModelSong) {
      return null
    }
    const songArtists = R.pipe(
      R.pathOr([], ["allStrapiSong", "nodes"]),
      R.find(R.propEq("id", `Song_${currentModelSong.id}`)),
      R.prop("artists"),
      R.map((artist: IArtist) => (
        <Link
          to={`/${intl.locale}/artists/${artist.slug}`}
          className="artist-link"
        >
          {artist.name}
        </Link>
      )),
      R.intersperse(<span> / </span>)
    )(data)
    return (
      <div className="detail-modal">
        <div className="modal-header ABC">Credits</div>
        <ul>
          {!!songArtists && (
            <li className="grid grid-cols-2 text-left ABC">
              <label>{intl.formatMessage({ id: "artist" })}</label>
              <span>{songArtists}</span>
            </li>
          )}
          {currentModelSong?.lyrics_by && (
            <li className="grid grid-cols-2 text-left ABC">
              <label>{intl.formatMessage({ id: "LyricsBy" })}</label>
              <span>{currentModelSong?.lyrics_by}</span>
            </li>
          )}
          {currentModelSong?.composed_by && (
            <li className="grid grid-cols-2 text-left ABC">
              <label>{intl.formatMessage({ id: "ComposedBy" })}</label>
              <span>{currentModelSong?.composed_by}</span>
            </li>
          )}
          {currentModelSong?.producer && (
            <li className="grid grid-cols-2 text-left ABC">
              <label>{intl.formatMessage({ id: "ArrangedBy" })}</label>
              <span>{currentModelSong?.producer}</span>
            </li>
          )}
          {currentModelSong?.vocal && (
            <li className="grid grid-cols-2 text-left ABC">
              <label>{intl.formatMessage({ id: "Vocal" })}</label>
              <span>{currentModelSong?.vocal}</span>
            </li>
          )}
          {currentModelSong?.engineer && (
            <li className="grid grid-cols-2 text-left ABC">
              <label>{intl.formatMessage({ id: "RecordedBy" })}</label>
              <span>{currentModelSong?.engineer}</span>
            </li>
          )}
          {currentModelSong?.mixed_by && (
            <li className="grid grid-cols-2 text-left ABC">
              <label>{intl.formatMessage({ id: "MixedBy" })}</label>
              <span>{currentModelSong?.mixed_by}</span>
            </li>
          )}
          {currentModelSong?.mastered_by && (
            <li className="grid grid-cols-2 text-left ABC">
              <label>{intl.formatMessage({ id: "MasteredBy" })}</label>
              <span>{currentModelSong?.mastered_by}</span>
            </li>
          )}
          {currentModelSong?.drum && (
            <li className="grid grid-cols-2 text-left ABC">
              <label>{intl.formatMessage({ id: "drum" })}</label>
              <span>{currentModelSong?.drum}</span>
            </li>
          )}
          {currentModelSong?.bass && (
            <li className="grid grid-cols-2 text-left ABC">
              <label>{intl.formatMessage({ id: "bass" })}</label>
              <span>{currentModelSong?.bass}</span>
            </li>
          )}
          {currentModelSong?.guitar && (
            <li className="grid grid-cols-2 text-left ABC">
              <label>{intl.formatMessage({ id: "guitar" })}</label>
              <span>{currentModelSong?.guitar}</span>
            </li>
          )}
          {currentModelSong?.piano && (
            <li className="grid grid-cols-2 text-left ABC">
              <label>{intl.formatMessage({ id: "piano" })}</label>
              <span>{currentModelSong?.piano}</span>
            </li>
          )}
          {currentModelSong?.synth && (
            <li className="grid grid-cols-2 text-left ABC">
              <label>{intl.formatMessage({ id: "synth" })}</label>
              <span>{currentModelSong?.synth}</span>
            </li>
          )}
          {currentModelSong?.backing_vocal && (
            <li className="grid grid-cols-2 text-left ABC">
              <label>{intl.formatMessage({ id: "backing_vocal" })}</label>
              <span>{currentModelSong?.backing_vocal}</span>
            </li>
          )}
        </ul>
      </div>
    )
  }

  return (
    <>
      <Seo title={current.name} />
      <article className="release-detail w-full">
        <div className="detail-header md:px-6 md:py-10 md:pb-0 flex flex-col items-end w-full border-b border-black">
          {renderSerialNumber()}
          <h1 className="PPG-Bold">{current.name}</h1>
          <sub className="self-start ABC">{artistNames}</sub>
        </div>
        <div className="detail-content grid grid-cols-1 md:grid-cols-2">
          <div className="p-6 border-b border-black detail-left-top order-2 md:order-1 flex flex-col items-start justify-start relative ABC-Mono">
            {current.release_type && <p>{current.release_type}</p>}
            {current.labels && (
              <p>{(current.labels || []).map(i => i.name).join(" / ")}</p>
            )}
            {current.created_at && (
              <p>{moment(current.created_at).format("MMM D, YYYY")}</p>
            )}
            <Popover
              isOpen={openShare}
              positions={["bottom"]}
              content={() => renderShareContent()}
              onClickOutside={() => toggleShare(false)}
              padding={8}
            >
              <button
                className="hem-external-link"
                onClick={() => {
                  toggleShare(true)
                }}
              >
                <span>SHARE</span>
              </button>
            </Popover>
            <div className="absolute right-0 md:inset-x-0 bottom-0 flex justify-end md:justify-between items-center px-8 py-6">
              <div
                className="border-4 border-black rounded-full hidden md:block"
                style={{ width: 37, height: 37 }}
              />
              <ul className="genres flex items-center gap-4 ABC-Mono">
                {genresString}
              </ul>
            </div>
          </div>
          <div className="cover-wrapper auto-rows-max order-1 md:order-2">
            <img
              src={`${current?.cover?.url}?x-oss-process=style/index_cover`}
              className="w-full cover"
            />
          </div>
          <div className="detail-left-bottom order-4 md:order-3">
            <ul
              className="appearance-none ABC"
              style={{
                overflow: "scroll",
                height: "calc(100% + 115px)",
              }}
            >
              {songs.map((song: ISong, idx: number) => renderSong(song, idx))}
            </ul>
          </div>
          <div
            ref={descRef}
            className="detail-description ABC order-3 md:order-4"
          >
            {(current.description || "").split("\n").map((item, index) => (
              <p key={index}>{item}</p>
            ))}
          </div>
          <div className="order-5"></div>
          <div className="order-6 detail-right-bottom bg-black flex justify-end items-center">
            <div
              className="border-4 border-white rounded-full"
              style={{ width: 37, height: 37 }}
            />
          </div>
        </div>
        <Modal>{renderCurrentModalContent()}</Modal>
      </article>
    </>
  )
}

export const pageQuery = graphql`
  query Release {
    allStrapiRelease(sort: { order: DESC, fields: published_at }) {
      nodes {
        name
        labels {
          name
        }
        published_at
        created_at
        release_type
        serial_number
        description
        slug
        locale
        genres {
          name
        }
        songs {
          name
          id
          lyrics_by
          mastered_by
          mixed_by
          engineer
          composed_by
          producer
          vocal
          drum
          bass
          guitar
          piano
          synth
          backing_vocal
          track {
            url
            alternativeText
          }
        }
        artists {
          name
          id
          slug
        }
        cover {
          url
        }
      }
    }
    allStrapiSong {
      nodes {
        id
        artists {
          id
          slug
          name
        }
      }
    }
  }
`
